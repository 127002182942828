import React, {useState} from 'react';
import Flow from "../components/Flow";
import LayoutPage from "../components/LayoutPage";
import AlbumService from "../services/api/album";
import AlbumCard from "../components/AlbumCard";

function Albums() {
    const [album, setAlbum] = useState([]);
    const [albumLoading, setAlbumLoading] = useState(false);
    const [albumError, setAlbumError] = useState(false);
    const [albumPage, setAlbumPage] = useState(1);
    const [hasMoreAlbum, setHasMoreAlbum] = useState(true);

    const loadMoreReports = () => {
        if (albumLoading === true || hasMoreAlbum === false || albumError === true)
            return;
        setAlbumLoading(true);
        AlbumService
            .fetchGroup(albumPage)
            .then(response => {
                if (response.data.length === 0) {
                    setHasMoreAlbum(false);
                } else {
                    let reportsCards = response.data.map(function (album) {
                        return (<AlbumCard
                            key={album.id}
                            title={album.title}
                            idAlbum={album.id}
                            date={album.date}
                        />)
                    });
                    setAlbum([...album, reportsCards]);
                    setAlbumPage(albumPage + 1);
                    setAlbumLoading(false);
                }
            })
            .catch(error => {
                console.debug(error);
                setAlbumLoading(false);
                setAlbumError(true);
            });
    }

    return (
        <LayoutPage
            title={"Les albums photos"}
        >
            <Flow
                pageStart={albumPage}
                loadMorePage={loadMoreReports}
                hasMorePage={hasMoreAlbum}
                content={album}
                height={"report-container font-color-2"}
            />
        </LayoutPage>
    );
};

export default Albums;
