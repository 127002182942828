import request from '../../shared/lib/request'


function fetchOne(id, type) {
    return request({
        url: `/${type}/${id}`,
        method: 'GET',
    });
}

function fetchPictures(id_album) {
    return request({
        url: `/picture-album/${id_album}`,
        method: 'GET',
    });
}

const DataService = {
    fetchOne, fetchPictures
}

export default DataService;
