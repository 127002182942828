import React from 'react';
import {Container} from "react-bootstrap";
import Layout from "./Layout";

const LayoutPage = (props) => {

    const {title, children} = props;

    return (
        <Layout>
            <Container className="pt-4">
                <div className="background-2 p-2 font-color-2">
                    <h2 className="p-2 text-center"><b>{title}</b></h2>
                    <div className="more-content p-2 background-1 font-color-2">
                        {children}
                    </div>
                </div>
            </Container>
        </Layout>
    );
};

export default LayoutPage;
