import React from 'react';
import './Loader.css'

const Loader = (props) => {
    return (
        <div className={`${props.classContainer} w-100 text-center`}>
            <svg viewBox="25 25 50 50" className={`${props.classLoader} loader`}>
                <circle cx="50" cy="50" r="20"/>
            </svg>
        </div>
    );
};

export default Loader;
