import request from '../../shared/lib/request'


function fetchGroup(page) {
    return request({
        url: `/news/group`,
        method: 'POST',
        data: {
            page,
        }
    });
}

const NewsService = {
    fetchGroup
}

export default NewsService;
