import React, { useEffect, useState } from "react";
import { Carousel, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserShield,
  faCity,
  faSearch,
  faMapMarkedAlt,
  faChild,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import GeneralService from "../services/api/general";
import { PICTURE_URL } from "../shared/constants";

const Layout = (props) => {
  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    GeneralService.fetchAll()
      .then((response) => {
        setPictures(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="page-container font-color-1 body-font min-vh-100 position-relative">
      <header>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="fixed-top background-2 rounded-bottom link-remove-style"
        >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto ml-auto">
              <h5 className="m-0">
                <Link to={{ pathname: `/` }} className="nav-item">
                  <FontAwesomeIcon icon={faHome} /> Accueil
                </Link>
              </h5>
            </Nav>
            <Nav className="mr-auto ml-auto">
              <h5 className="m-0">
                <NavDropdown
                  title={
                    <>
                      <FontAwesomeIcon icon={faCity} /> Vie Municipale
                    </>
                  }
                >
                  <Link
                    to={{ pathname: `/comptes-rendus` }}
                    className="dropdown-item"
                  >
                    Comptes rendus de Conseil Municipal
                  </Link>
                  <Link
                    to={{ pathname: `/bulletins` }}
                    className="dropdown-item"
                  >
                    Bulletins d’informations
                  </Link>
                  <Link
                    to={{ pathname: `/page/conseil-municipal` }}
                    className="dropdown-item"
                  >
                    Présentation de la commune
                  </Link>
                </NavDropdown>
              </h5>
            </Nav>
            <Nav className="mr-auto ml-auto">
              <h5 className="m-0">
                <NavDropdown
                  title={
                    <>
                      <FontAwesomeIcon icon={faSearch} /> Découvrir Peuplingues
                    </>
                  }
                >
                  <Link
                    to={{ pathname: `/page/presentation-de-la-commune` }}
                    className="dropdown-item"
                  >
                    Présentation de la commune
                  </Link>
                  <Link
                    to={{ pathname: `/page/historique-et-patrimoine` }}
                    className="dropdown-item"
                  >
                    Historique et Patrimoine
                  </Link>
                  <NavDropdown title={<>Tourisme</>} className="submenu">
                    <Link
                      to={{ pathname: `/page/gites` }}
                      className="dropdown-item"
                    >
                      Gîtes
                    </Link>
                    <Link
                      to={{ pathname: `/page/camping` }}
                      className="dropdown-item"
                    >
                      Camping
                    </Link>
                  </NavDropdown>
                  <Link to={{ pathname: `/albums` }} className="dropdown-item">
                    Le coin photos
                  </Link>
                </NavDropdown>
              </h5>
            </Nav>
            <Nav className="mr-auto ml-auto">
              <h5 className="m-0">
                <NavDropdown
                  title={
                    <>
                      <FontAwesomeIcon icon={faMapMarkedAlt} /> Vivre à
                      Peuplingues
                    </>
                  }
                >
                  <NavDropdown
                    title={<>Démarches Administratives</>}
                    className="submenu"
                  >
                    <a
                      href={"https://passeport.ants.gouv.fr/"}
                      target={"_blank"}
                      className="dropdown-item"
                    >
                      Cartes d'dentité/passeports
                    </a>
                    <Link
                      to={{ pathname: `/page/listes-electorales` }}
                      className="dropdown-item"
                    >
                      S'inscrire sur les listes électorales
                    </Link>
                  </NavDropdown>
                  <NavDropdown title={<>État Civil</>} className="submenu">
                    <Link
                      to={{ pathname: `/page/mariage` }}
                      className="dropdown-item"
                    >
                      Mariage
                    </Link>
                    <Link
                      to={{ pathname: `/page/pacs` }}
                      className="dropdown-item"
                    >
                      PACS
                    </Link>
                    <Link
                      to={{ pathname: `/page/demande-d-actes` }}
                      className="dropdown-item"
                    >
                      Demande d'actes
                    </Link>
                    <Link
                      to={{ pathname: `/page/demande-de-livret-de-famille` }}
                      className="dropdown-item"
                    >
                      demande de livret de famille
                    </Link>
                  </NavDropdown>
                  <Link
                    to={{ pathname: `/page/eau-et-assainissement` }}
                    className="dropdown-item"
                  >
                    Eau et Assainissement
                  </Link>
                  <Link
                    to={{ pathname: `/page/vie-culturelle` }}
                    className="dropdown-item"
                  >
                    Vie Culturelle
                  </Link>
                  <Link
                    to={{ pathname: `/page/maison-sante` }}
                    className="dropdown-item"
                  >
                    Maison de santé
                  </Link>
                  <Link
                    to={{ pathname: `/page/culte` }}
                    className="dropdown-item"
                  >
                    Culte
                  </Link>
                  <Link
                    to={{ pathname: `/page/urbanisme` }}
                    className="dropdown-item"
                  >
                    Urbanisme
                  </Link>
                  <Link
                    to={{ pathname: `/page/location-de-la-salle-des-fetes` }}
                    className="dropdown-item"
                  >
                    Location de la salle des Fêtes
                  </Link>
                  <a
                    href={"https://www.grandcalais.fr/"}
                    target={"_blank"}
                    className="dropdown-item"
                  >
                    Intercommunalité
                  </a>
                  <Link
                    to={{ pathname: `/association` }}
                    className="dropdown-item"
                  >
                    Les associations
                  </Link>
                  <NavDropdown title={<>Environnement</>} className="submenu">
                    <Link
                      to={{ pathname: `/page/dechetterie` }}
                      className="dropdown-item"
                    >
                      Déchetterie
                    </Link>
                    <Link
                      to={{ pathname: `/page/chemin-de-randonnee` }}
                      className="dropdown-item"
                    >
                      Chemin de Randonnée
                    </Link>
                  </NavDropdown>
                </NavDropdown>
              </h5>
            </Nav>
            <Nav className="mr-auto ml-auto">
              <h5 className="m-0">
                <NavDropdown
                  title={
                    <>
                      <FontAwesomeIcon icon={faChild} /> Jeunesse
                    </>
                  }
                >
                  <Link
                    to={{ pathname: `/page/ecole-primaire` }}
                    className="dropdown-item"
                  >
                    École
                  </Link>
                  <Link
                    to={{ pathname: `/page/accueil-de-loisirs-intercommunal` }}
                    className="dropdown-item"
                  >
                    Accueil de Loisirs Intercommunal
                  </Link>
                  <Link
                    to={{ pathname: `/page/relais-assitantes-maternelles` }}
                    className="dropdown-item"
                  >
                    Relais d'Assitantes Maternelles
                  </Link>
                </NavDropdown>
              </h5>
            </Nav>
            <Nav className="mr-auto ml-auto">
              <h5 className="m-0">
                <NavDropdown
                  title={
                    <>
                      <FontAwesomeIcon icon={faUserShield} /> Sécurité
                    </>
                  }
                >
                  <Link
                    to={{ pathname: `/page/defibrillateurs` }}
                    className="dropdown-item"
                  >
                    Défibrillateurs
                  </Link>
                  <Link
                    to={{ pathname: `/page/plan-canicule-et-grand-froid` }}
                    className="dropdown-item"
                  >
                    Plan Canicule <br />
                    et Grand Froid
                  </Link>
                  <Link
                    to={{ pathname: `/page/monoxyde-de-carbone` }}
                    className="dropdown-item"
                  >
                    Monoxyde de carbone
                  </Link>
                  <Link
                    to={{ pathname: `/page/cambriolages` }}
                    className="dropdown-item"
                  >
                    Cambriolages
                  </Link>
                  <Link
                    to={{ pathname: `/page/urgences` }}
                    className="dropdown-item"
                  >
                    Urgences
                  </Link>
                </NavDropdown>
              </h5>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>

      <div className="page-content w-100 h-100">
        <Container className="carousel-container background-2 p-2">
          <Carousel controls={false} className="carousel-ma in">
            {pictures.map((picture, index) => (
              <Carousel.Item key={index}>
                <div className="parent">
                  <img
                    className="d-block w-100 carousel-picture"
                    src={`${PICTURE_URL}/${picture.image}`}
                    alt=""
                  />
                  <img
                    className="name rounded m-2"
                    src={process.env.PUBLIC_URL + "/images/name.png"}
                    alt=""
                  />
                  <img
                    className="logo m-2"
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt=""
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
        <div className="pb-4">{props.children}</div>
      </div>
      <footer className="text-center">
        <div className="background-1 w-100 p-2 rounded-top">
          © 2019 - PEUPLINGUES
        </div>
      </footer>
    </div>
  );
};

export default Layout;
