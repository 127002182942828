import React from 'react';
import {Col, Row} from "react-bootstrap";

const ReportCard = (props) => {
    const {date, report, content} = props;
    return (
        <div>
            <Row>
                <Col md={2} className="pt-2">
                    <a href={report} target="_blank" rel="noopener noreferrer">
                        <img className="m-2 w-25 cursor-pointer" src={process.env.PUBLIC_URL + '/images/pdf.png'}
                             alt=""/>
                    </a>
                </Col>
                <Col md={10}>
                    <div>
                        <h5><b>{`${content} ${new Date(date).toLocaleDateString('fr-FR')}`}</b></h5>
                    </div>
                </Col>
            </Row>
            <hr/>
        </div>
    );
};

export default ReportCard;
