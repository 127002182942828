import SimpleBar from "simplebar-react";
import {Container} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "./Loader";
import React from "react";
import 'simplebar/dist/simplebar.min.css';

const Flow = (props) => {
    const {pageStart, loadMorePage, hasMorePage, content, height} = props;
    return (
        <SimpleBar className={height || `agenda-container`}>
            <Container>
                <InfiniteScroll
                    pageStart={pageStart}
                    loadMore={loadMorePage}
                    hasMore={hasMorePage}
                    loader={<Loader classContainer="pt-2" key={0}/>}
                    useWindow={false}
                >
                    {content}
                </InfiniteScroll>
            </Container>
        </SimpleBar>
    );
};

export default Flow;
