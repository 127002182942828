import React, {useEffect, useState} from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import Layout from "../components/Layout";
import {Container} from "react-bootstrap";
import DataService from "../services/api/data";
import {PICTURE_URL} from "../shared/constants";

function Pictures({match}) {

    const [pictures, setPictures] = useState([]);
    const [picturesLoadind, setPicturesLoading] = useState(false);

    useEffect(() => {
        const idAlbum = JSON.parse(match.params.id);
        if (picturesLoadind === true)
            return;
        setPicturesLoading(true);
        DataService
            .fetchPictures(idAlbum)
            .then(response => {
                response.pictures.map((pic) => {
                    pic.original = `${PICTURE_URL}/${pic.original}`;
                });
                setPictures(response.pictures);
                setPicturesLoading(false);
            })
            .catch(error => {
                console.debug(error);
                setPicturesLoading(false);
            });
    }, []);

    return (
        <Layout>
            <Container className="pt-4">
                <div className="more-content p-2 background-1 font-color-2 gallery">
                    <ImageGallery
                        items={pictures}
                        showThumbnails={false}
                        lazyLoad={true}
                        infinite={true}
                    />
                </div>
            </Container>
        </Layout>

    );
};

export default Pictures;
