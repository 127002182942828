import React from 'react';
import {Link} from "react-router-dom";

const AgendaCard = (props) => {
    const {id, title, date, dateEnd, type, displayDateEnd} = props;
    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const date_start_format = new Date(date);
    const date_end_format = new Date(dateEnd);
    return (
        <div>
            <Link to={{pathname: `/more`, search: `?type=${type}&id=${id}`}}>
                <h6><b>{title}</b></h6>
            </Link>
            {displayDateEnd === 1 &&
            <p>{`Du ${date_start_format.toLocaleDateString('fr-FR', options)} au ${date_end_format.toLocaleDateString('fr-FR', options)}`}</p>
            }
            {displayDateEnd === 0 &&
            <p>{`Le ${date_start_format.toLocaleDateString('fr-FR', options)}`}</p>
            }
            <hr/>
        </div>
    );
};

export default AgendaCard;
