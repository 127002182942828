import React, {useEffect, useState, useCallback} from 'react';
import LayoutPage from "../components/LayoutPage";
import 'react-quill/dist/quill.core.css';
import Loader from "../components/Loader";
import PageService from "../services/api/page";

function Page({match}) {
    const [slug, setSlug] = useState(match.params.slug);
    const [loadData, setLoadData] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    useEffect(() => {
        setSlug(match.params.slug);
        setLoadData(false);
    }, [match]);

    const fetchData = useCallback(
        () => {
            PageService
                .fetchOne(slug)
                .then(response => {
                    setLoadData(true);
                    setTitle(response.element.title);
                    setContent(response.element.content);
                })
                .catch(error => {
                    setLoadData(true);
                    setTitle("Page inconnue");
                    console.debug(error);
                });
        }, [slug],
    );

    useEffect(() => {
        if (loadData === false) {
            fetchData();
        }
    }, [loadData, fetchData, slug]);

    return (
        <LayoutPage title={title}>
            {loadData === false && <Loader/>}
            <div className="p-2 mt-4 more-html-container ql-editor" dangerouslySetInnerHTML={{__html: content}}/>
        </LayoutPage>
    );
};

export default Page;
