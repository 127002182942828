import request from '../../shared/lib/request'

function fetchGroup(page) {
    return request({
        url: `/association/group`,
        method: 'POST',
        data: {
            page,
        }
    });
}

const AssociationService = {
    fetchGroup
}

export default AssociationService;
