import React, {useState} from 'react';
import Flow from "../components/Flow";
import ReportCard from "../components/ReportCard";
import LayoutPage from "../components/LayoutPage";
import MunicipalNewsLetter from "../services/api/municipalNewsLetter";

function NewsLetter() {
    const [newsLetter, setNewsLetter] = useState([]);
    const [newsLetterLoading, setNewsLetterLoading] = useState(false);
    const [newsLetterError, setNewsLetterError] = useState(false);
    const [NewsLetterPage, setNewsLetterPage] = useState(1);
    const [hasMoreNewsLetter, setHasMoreNewsLetter] = useState(true);

    const loadMoreNewsLetter = () => {
        if (newsLetterLoading === true || hasMoreNewsLetter === false || newsLetterError === true)
            return;
        setNewsLetterLoading(true);
        MunicipalNewsLetter
            .fetchGroup(NewsLetterPage)
            .then(response => {
                if (response.data.length === 0) {
                    setHasMoreNewsLetter(false);
                } else {
                    let newsLetterCards = response.data.map(function (newsLetter) {
                        return (<ReportCard
                            key={newsLetter.id}
                            date={newsLetter.date}
                            report={newsLetter.report}
                            content={"Bulletin municipal du "}
                        />)
                    });
                    setNewsLetter([...newsLetter, newsLetterCards]);
                    setNewsLetterPage(NewsLetterPage + 1);
                    setNewsLetterLoading(false);
                }
            })
            .catch(error => {
                console.debug(error);
                setNewsLetterLoading(false);
                setNewsLetterError(true);
            });
    }

    return (
        <LayoutPage
            title={"Bulletins municipaux"}
        >
            <Flow
                pageStart={NewsLetterPage}
                loadMorePage={loadMoreNewsLetter}
                hasMorePage={hasMoreNewsLetter}
                content={newsLetter}
                height={"report-container font-color-2"}
            />
        </LayoutPage>
    );
};

export default NewsLetter;
