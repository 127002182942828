import React from 'react';
import {Col, Row} from "react-bootstrap";
import {PICTURE_URL} from "../shared/constants";
import {Link} from "react-router-dom";

const AssociationCard = (props) => {
    const {id, image, title, spoil} = props;
    return (
        <div>
            <Row>
                <Col md={1} className="pt-2">
                    <img className="m-2 w-100 img-news" src={`${PICTURE_URL}/${image}`}
                         alt=""/>
                </Col>
                <Col md={11}>
                    <div>
                        <Link to={{pathname: `/more`, search: `?type=association&id=${id}`}}>
                            <h5 className="text-center"><b>{title}</b></h5>
                            <p className="text-justify">{spoil}</p>
                        </Link>
                    </div>
                </Col>
            </Row>
            <hr/>
        </div>
    );
};

export default AssociationCard;
