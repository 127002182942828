import React, {useState} from 'react';
import Flow from "../components/Flow";
import ReportCard from "../components/ReportCard";
import MunicipalReportService from "../services/api/municipalReport";
import LayoutPage from "../components/LayoutPage";
import {PICTURE_URL} from "../shared/constants";

function Report() {
    const [report, setReport] = useState([]);
    const [reportLoading, setReportLoading] = useState(false);
    const [reportError, setReportError] = useState(false);
    const [reportPage, setReportPage] = useState(1);
    const [hasMoreReport, setHasMoreReport] = useState(true);

    const loadMoreReports = () => {
        if (reportLoading === true || hasMoreReport === false || reportError === true)
            return;
        setReportLoading(true);
        MunicipalReportService
            .fetchGroup(reportPage)
            .then(response => {
                if (response.data.length === 0) {
                    setHasMoreReport(false);
                } else {
                    let reportsCards = response.data.map(function (report) {
                        return (<ReportCard
                            key={report.id}
                            date={report.date}
                            report={`${PICTURE_URL}/${report.report}.pdf`}
                            content={"Compte rendu du "}
                        />)
                    });
                    setReport([...report, reportsCards]);
                    setReportPage(reportPage + 1);
                    setReportLoading(false);
                }
            })
            .catch(error => {
                console.debug(error);
                setReportLoading(false);
                setReportError(true);
            });
    }

    return (
        <LayoutPage
            title={"Comptes rendus du conseil municipal"}
        >
            <Flow
                pageStart={reportPage}
                loadMorePage={loadMoreReports}
                hasMorePage={hasMoreReport}
                content={report}
                height={"report-container font-color-2"}
            />
        </LayoutPage>
    );
};

export default Report;
