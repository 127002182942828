import request from '../../shared/lib/request'

function fetchGroup(page) {
    return request({
        url: `/company/group`,
        method: 'POST',
        data: {
            page,
        }
    });
}

const CompanyService = {
    fetchGroup
}

export default CompanyService;
