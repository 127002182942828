import React, {useState} from 'react';
import Flow from "../components/Flow";
import LayoutPage from "../components/LayoutPage";
import CompanyService from "../services/api/company";
import CompanyCard from "../components/CompanyCard";

function Company() {
    const [company, setCompany] = useState([]);
    const [companyLoading, setCompanyLoading] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [companyPage, setCompanyPage] = useState(1);
    const [hasMoreCompany, setHasMoreCompany] = useState(true);

    const loadMoreCompany = () => {
        if (companyLoading === true || hasMoreCompany === false || companyError === true)
            return;
        setCompanyLoading(true);
        CompanyService
            .fetchGroup(companyPage)
            .then(response => {
                if (response.data.length === 0) {
                    setHasMoreCompany(false);
                } else {
                    let companyCards = response.data.map(function (company) {
                        return (<CompanyCard
                            key={company.id}
                            id={company.id}
                            title={company.title}
                            image={company.image}
                            spoil={company.spoil}
                        />)
                    });
                    setCompany([...company, companyCards]);
                    setCompanyPage(companyPage + 1);
                    setCompanyLoading(false);
                }
            })
            .catch(error => {
                console.debug(error);
                setCompanyLoading(false);
                setCompanyError(true);
            });
    }

    return (
        <LayoutPage
            title={"Entreprises"}
        >
            <Flow
                pageStart={companyPage}
                loadMorePage={loadMoreCompany}
                hasMorePage={hasMoreCompany}
                content={company}
                height={"report-container font-color-2 link-remove-style"}
            />
        </LayoutPage>
    );
};

export default Company;
