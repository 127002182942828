import React, {useEffect, useState, useCallback} from 'react';
import DataService from "../services/api/data";
import LayoutPage from "../components/LayoutPage";
import 'react-quill/dist/quill.core.css';
import Loader from "../components/Loader";

function More({location}) {

    let params = new URLSearchParams(location.search);
    const id = params.get("id");
    const type = params.get("type");

    const [loadData, setLoadData] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [date, setDate] = useState(new Date());
    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

    const fetchData = useCallback(
        () => {
            DataService
                .fetchOne(id, type)
                .then(response => {
                    setLoadData(true);
                    setTitle(response.element.title);
                    setContent(response.element.content);
                    if (type === "events") {
                        setDate(new Date(response.element.date));
                    }
                })
                .catch(error => {
                    setLoadData(true);
                    console.debug(error);
                });
        }, [],
    );

    useEffect(() => {
        if (loadData === false) {
            fetchData();
        }
    }, [loadData, fetchData]);

    return (
        <LayoutPage
            title={title}
        >
            {type === "events" &&
            <h3 className="text-center text-capitalize">{date.toLocaleDateString('fr-FR', options)}</h3>}
            {loadData === false && <Loader/>}
            <div className="p-2 mt-4 more-html-container ql-editor" dangerouslySetInnerHTML={{__html: content}}/>
        </LayoutPage>
    );
};

export default More;
