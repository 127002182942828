import request from '../../shared/lib/request'

function fetchGroup(page) {
    return request({
        url: `/municipal-news-letter/group`,
        method: 'POST',
        data: {
            page,
        }
    });
}

const MunicipalNewsLetter = {
    fetchGroup
}

export default MunicipalNewsLetter;
