import request from '../../shared/lib/request'

function fetchAll() {
    return request({
        url: `/general`,
        method: 'GET',
    });
}

const GeneralService = {
    fetchAll,
}

export default GeneralService;
