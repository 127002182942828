import React from 'react';
import {Link} from "react-router-dom";

const AlbumCard = (props) => {
    const {idAlbum, title, date} = props;
    return (
        <div>
            <div className="text-center link-remove-style">
                <Link to={{pathname: `/albums/${idAlbum}`}}>
                    <h5><b>{title}</b></h5>
                </Link>
                <p>{`${new Date(date).toLocaleDateString('fr-FR')}`}</p>
            </div>
            <hr/>
        </div>
    );
};

export default AlbumCard;
