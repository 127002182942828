import React from 'react';

const HomeCard = (props) => {
    const {containerStyle, titleContainerStyle, titleStyle, title, children, childrenStyle} = props;
    return (
        <div className={containerStyle || "border rounded mt-4"}>
            {title && <div className={titleContainerStyle || "text-center background-2 pt-2"}>
                <h3 className={titleStyle || "pb-3"}>{title}</h3>
            </div>}
            <div className={`background-1 ${childrenStyle}`}>
                {children}
            </div>
        </div>
    );
};

export default HomeCard;
