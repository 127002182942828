import request from '../../shared/lib/request'

function fetchGroup(page) {
    return request({
        url: `/events/group`,
        method: 'POST',
        data: {
            page,
        }
    });
}

const EventService = {
    fetchGroup
}

export default EventService;
