import React, {useState} from 'react';
import Flow from "../components/Flow";
import LayoutPage from "../components/LayoutPage";
import AssociationService from "../services/api/association";
import AssociationCard from "../components/AssociationCard";

function Association() {
    const [association, setAssociation] = useState([]);
    const [associationLoading, setAssociationLoading] = useState(false);
    const [associationError, setAssociationError] = useState(false);
    const [associationPage, setAssociationPage] = useState(1);
    const [hasMoreAssociation, setHasMoreAssociation] = useState(true);

    const loadMoreAssociation = () => {
        if (associationLoading === true || hasMoreAssociation === false || associationError === true)
            return;
        setAssociationLoading(true);
        AssociationService
            .fetchGroup(associationPage)
            .then(response => {
                if (response.data.length === 0) {
                    setHasMoreAssociation(false);
                } else {
                    let newsLetterCards = response.data.map(function (association) {
                        return (<AssociationCard
                            key={association.id}
                            id={association.id}
                            title={association.title}
                            image={association.image}
                            spoil={association.spoil}
                        />)
                    });
                    setAssociation([...association, newsLetterCards]);
                    setAssociationPage(associationPage + 1);
                    setAssociationLoading(false);
                }
            })
            .catch(error => {
                console.debug(error);
                setAssociationLoading(false);
                setAssociationError(true);
            });
    }

    return (
        <LayoutPage
            title={"Associations"}
        >
            <Flow
                pageStart={associationPage}
                loadMorePage={loadMoreAssociation}
                hasMorePage={hasMoreAssociation}
                content={association}
                height={"report-container font-color-2 link-remove-style"}
            />
        </LayoutPage>
    );
};

export default Association;
