import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import AppRoute from "./router";

function App() {
    return (
        <div className="App">
            <AppRoute/>
        </div>
    );
}

export default App;
