import React from 'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {PICTURE_URL} from "../shared/constants";

const NewsCard = (props) => {
    const {id, title, image, spoil, type} = props;
    return (
        <div>
            <Row>
                <Col md={2} className="text-center pt-2">
                    <img className="img-news" src={`${PICTURE_URL}/${image}`} alt=""/>
                </Col>
                <Col>
                    <Link to={{pathname: `/more`, search: `?type=${type}&id=${id}`}}>
                        <h5><b>{title}</b></h5>
                    </Link>
                    <p className="text-justify">{spoil}</p>
                </Col>
            </Row>
            <hr/>
        </div>
    );
};

export default NewsCard;
