import React, {useState, useEffect} from 'react';
import axios from 'axios'

function Weather() {

    const [temp, setTemp] = useState(0);
    const [description, setDescription] = useState("");
    const [icon, setIcon] = useState("");

    useEffect(() => {
        axios.get('http://api.openweathermap.org/data/2.5/weather', {
            params: {
                id: 6618424,
                lang: 'fr',
                units: 'metric',
                appid: '38fa65232ef972f2a31f0f46387386c3'
            }
        })
            .then(function (response) {
                setDescription(response.data.weather[0].description);
                setIcon(response.data.weather[0].icon);
                setTemp(response.data.main.temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    return (
        <>
            <img alt="" src={`http://openweathermap.org/img/wn/${icon}.png`} className="weather-logo"/>
            <h3>{temp.toFixed(1)}°</h3>
            <p className="text-capitalize">{description}</p>
        </>
    );
};

export default Weather;


