import request from '../../shared/lib/request'


function fetchOne(slug) {
    return request({
        url: `/page-slug/${slug}`,
        method: 'GET',
    });
}

const PageService = {
    fetchOne
}

export default PageService;
